/* Boi */
:root {
  --blueDarker: #13224d;
  --blue: #213a80;
  --blueLighter : #355dcc;
  --orangeDarker : #e96900;
  --orangeDark: #f19100;
  --orangeBright: #f79420;
  --cancel: #bd6f6f;
  --confirm: #1a660c;
  --save: #1a660c;
  --add: #213a80;
}

/* Table overrieds */
div .MuiTypography-h6 {
  font-weight: bold;
}
.MuiTableBody-root tr:nth-child(2n + 1){
  background-color: #ddd;
}
.MuiTableRow-root .MuiTableCell-body  {
  padding: 0px 3px;
}

.MuiTableBody-root tr:hover {
  background-color: #d6c37c !important;
}
.MuiTableRow-head th span{
  font-weight: bold !important; 
}

/* FIX za select na menijata vo analiza, bez ova se gubit scrollbar i se dodavat padding-right od 16px*/
body {
  padding-right: 0px !important;
  overflow-y: scroll !important;
  margin-bottom: 35px !important;
}

/* Buttons */
/* Confirm */
.buttonConfirm {
	color: #fff !important;
	text-transform: uppercase;
	background-color: var(--confirm);
	padding: 6px;
  margin: 0 10px 20px 10px;
	border-radius: 5px;
  display: inline-block;
	transition: all 0.4s ease 0s;
}
.buttonConfirm:hover {
  cursor: pointer;
	letter-spacing: 0.5px;
	transition: all 0.4s ease 0s;
}
/* Add */
.buttonAdd {
	color: #fff !important;
	text-transform: uppercase;
	background-color: var(--add);
	padding: 6px;
  margin: 0 10px 20px 10px;
	border-radius: 5px;
  display: inline-block;
	transition: all 0.4s ease 0s;
}
.buttonAdd:hover {
  cursor: pointer;
	letter-spacing: 0.5px;
	transition: all 0.4s ease 0s;
}
/* Save */
.buttonSave {
	color: #fff !important;
	text-transform: uppercase;
	background-color: var(--save);
	padding: 6px;
  margin: 0 10px 20px 10px;
	border-radius: 5px;
  display: inline-block;
	transition: all 0.4s ease 0s;
}
.buttonSave:hover {
  cursor: pointer;
	letter-spacing: 0.5px;
	transition: all 0.4s ease 0s;
}
/* Cancel */
.buttonCancel {
	color: #fff !important;
	text-transform: uppercase;
	background-color: var(--cancel);
	padding: 6px;
  margin: 0 10px 20px 10px;
	border-radius: 5px;
  display: inline-block;
	transition: all 0.4s ease 0s;
}
.buttonCancel:hover {
  cursor: pointer;
	letter-spacing: 0.5px;
	transition: all 0.4s ease 0s;
}

/* Edit Section */
.editSectionGlavenNaslov {
  width: 550px;
  border-bottom: 3px solid gray;
  color: gray;
  text-align: center;
  margin: auto;
  margin-top: 50px;
  box-sizing: border-box;
}
.editSectionNaslovOff {
  width: 300px;
  border-bottom: 2px solid var(--blue);
  padding: 5px;
  margin: auto;
  display: block;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.editSectionNaslovOff:hover {
  cursor: pointer;
}
.editSectionNaslovOn {
  /* background-color:  var(--orangeBright); */
  opacity: 0.8;
  width: 340px;
  border-bottom: 3px solid var(--orangeBright);
  padding: 5px;
  margin: auto;
  display: block;
  text-align: center;
  box-sizing: border-box;
}
.editSectionNaslovOn:hover{
  cursor: pointer;
}
.editSection {
  margin: auto;
  margin-top: 50px;
  margin-bottom: 100px;
  width: 600px;
}
.editSectionLeft {
  width: 600px;
  margin-top: 30px;
  margin-bottom: 100px;
  margin-left: 200px;
  position: relative;
  float: left;
}
.editSectionRight {
  width: 600px;
  margin-top: 30px;
  margin-bottom: 100px;
  margin-right: 200px;
  position: relative;
  float: right;
}
.cellMultiline {
  width: 450px;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}
.cellSingle {
  width: 300px;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}
.cellDual {
  width: 280px;
  margin: 10px;
  box-sizing: border-box;
  float: left;
}
.dropDownDouble {
  width: 45%;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 2.5%;
  margin-right: 2.5%;
  box-sizing: border-box;
  display: inline-block;
}
.dropDownSingle {
  width: 50%;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 25%;
  margin-right: 25%;
  display: inline-block;
  box-sizing: border-box;
}

/* Table Full Screab */
.tableFS {
  margin-top: 30px;
  margin-right: 10px;
  margin-left: 10px;
  box-sizing: border-box;
}

/* Add section */
.addSectionGlavenNaslov {
  width: 550px;
  border-bottom: 3px solid gray;
  color: gray;
  text-align: center;
  margin: auto;
  margin-top: 30px;
  box-sizing: border-box;
}
.naslovMal {
  width: 140px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
  padding-bottom: 3px;
  border-bottom: 2px solid var(--blue);
}
.addSection {
  margin-top: 20px;
}
/* Add section Full Screan */
.childAddFS {
  width: 20%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 10px;
  margin-bottom: 20px;
  display: inline-block;
  box-sizing: border-box;
}
/* Add section Split Screen */
.childAddSS {
  width: 40%;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 5%;
  margin-right: 5%;
  display: inline-block;
  box-sizing: border-box;
}
.splitScreenDiv {
  margin: auto;
  margin-top: 30px;
  width: 1000px;
}
/* Media queries for responsive design */
@media (max-width: 1650px) {
  .editSectionLeft {
    margin-left: 100px;
  }
  .editSectionRight {
    margin-right: 100px;
  }
}
@media (max-width: 1420px) {
  .editSectionLeft {
    margin-left: 20px;
  }
  .editSectionRight {
    margin-right: 20px;
  }
}
@media (max-width: 1260px) {
  .editSectionLeft {
    margin: auto;
    margin-top: 30px;
    float: none;
  }
  .editSectionRight {
    margin-right: auto;
    margin-left: auto;
    margin-top: 0px;
    float: none;
  }
}
@media (max-width: 1000px) {
  .splitScreenDiv {
    width: 100%;
  }
}
@media (max-width: 800px) {
  .childAddFS {
    width: 40%;
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media (max-width: 625px) {
  .editSectionNaslovOn {
    width: 300px;
  }
  .cellMultiline {
    width: 100%;
  }
  .dropDownDouble {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .dropDownSingle {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .editSection {
    width: 300px;
  }
  .editSectionLeft {
    width: 300px;
  }
  .editSectionRight {
    width: 300px;
  }
  .editSectionGlavenNaslov {
    width: 300px;
  }
  .addSectionGlavenNaslov {
    width: 300px;
  }
}
@media (max-width: 450px) {
  .childAddFS {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .childAddSS {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}


@font-face {
  font-family: 'futura_hv_btheavy';
  src: url('./futuhv-webfont.woff2') format('woff2'),
       url('./futuhv-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'futura_bk_btbook';
  src: url('./futubk-webfont.woff2') format('woff2'),
       url('./futubk-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}