.greatDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}
.greatDiv td:first-child {
    text-align: right;
    width: 40%;
    font-size: 1.08em;
}
.greatDiv td:last-child {
    width: 40%;
    padding: 1px;
    border-bottom: 1px solid #213a80;
    font-size: 1.08em;
    padding-left: 10px;
}
.greatDivFloating {
}
.greatDivFloating td:first-child {
    text-align: right;
    width: 40%;
    font-size: 1.08em;
}
.greatDivFloating td:last-child {
    width: 40%;
    padding: 1px;
    border-bottom: 1px solid #213a80;
    font-size: 1.08em;
    padding-left: 10px;
}
table {
    border-spacing: 15px 0px;
    margin : auto;
    text-align : left;
}
.legendSpan {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}
.legendDiv {
    text-align: left;
    margin-left: 30px;
}
.legendDiv:last-child {
    padding-bottom: 60px;
}